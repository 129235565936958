
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import UiPaginationComponent from "@/views/components/tirscript3-table/tirscript3-pagination.vue";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";

@Options<TableView>({
  name: "TableView",
})
export default class TableView extends Vue {
  @Prop({ required: true }) getDataFuncAsync: Function;
  @Prop({ default: false }) noPagination: boolean;
  @Prop() filter: {};

  innerFilter: PageRequest = { Skip: 0, Take: 20 };

  isLoading = false;
  totalItems = 0;
  currentPage = 1;
  innerItems: any[] = [];

  hasFilter = false;

  countPerPage = 10;
  pagination = true;
  search = "";
  countOnPageItems = [];

  @Watch("filter", { deep: true })
  onFilter() {
    this.refresh();
  }
  mounted() {
    this.countOnPageItems = [
      new ListViewItem(new DefaultModel(1, "10"), true, false),
      new ListViewItem(new DefaultModel(2, "20"), false, false),
      new ListViewItem(new DefaultModel(3, "50"), false, false),
      new ListViewItem(new DefaultModel(4, "100"), false, false),
    ];
  }
  async created() {
    await this.refresh();
  }

  sort(sortName: string, sortType: string) {
    // if (sortName != "" && this.innerFilter.Sort == null) {
    //   this.innerFilter.Sort = { FieldName: "", Desc: false };
    // }
    // this.innerFilter.Sort.FieldName = sortName;
    // this.innerFilter.Sort.Desc = sortType == "1";
    // (this.$refs.pagination as UiPaginationComponent).currentPage = 1;

    this.refresh();
  }

  async refresh() {
    this.isLoading = true;
    let response;
    // if (this.innerFilter.Sort && this.innerFilter.Sort.FieldName == "") {
    //   this.innerFilter.Sort = null;
    // }

    try {
      response = await this.getDataFuncAsync(this.innerFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
    } finally {
      this.isLoading = false;
    }

    this.totalItems = response.Count;
    this.innerItems = response.Items;
  }

  get pageSize(): number {
    const x = this.innerFilter.Take;
    return x;
  }

  resetPagination() {
    this.innerFilter.Skip = 0;
    this.currentPage = 1;
    this.totalItems = 0;
  }
  onSort(sortName: string, sortType: string) {
    // console.log("sortType", sortType);
    // this.resetPagination();
    // this.filter.Sort = new SortRequest({
    //   FieldName: sortName,
    //   Desc: sortType == "1",
    // });
    // this.refresh();
  }

  onChangePagePerPage(item: ListViewItem<DefaultModel>[]) {
    this.innerFilter.Take = Number((item[0].Value as DefaultModel).name);
    this.resetPagination();
    this.refresh();
  }

  onPaginationChanged(currentPage: number) {
    this.currentPage = currentPage;
    this.innerFilter.Skip = (currentPage - 1) * this.innerFilter.Take;
    this.refresh();
  }
}
